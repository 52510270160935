.terms-and-privacy-container {
  h4 {
    font-size: 1.3rem;
  }

  .container {
    width: 80% !important;
  }

  p {
    margin-bottom: 1.5rem !important;
    font-weight: thin !important;
  }

  p.ml-4 {
    margin-left: 1.5rem !important;
  }

  p.ml-5 {
    margin-left: 1.7rem !important;
    padding-left: 2rem !important;
  }

  p.mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .table {
    color: #4f5d79;
    margin-bottom: 0.9rem !important;
  }

  .table .bg-secondary {
    background-color: #ced2d8 !important;
  }

  a,
  .text-primary {
    color: #321fdb !important;
  }
}

@media (max-width: 640px) {
  .terms-and-privacy-container {
    .container {
      width: 90% !important;
    }
  }
}
